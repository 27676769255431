import 'css/flux/modules/Modal.less';
import 'jquery';
import { cookieBase } from 'server-data/flconfigs';
import { oneLine } from 'common-tags';
import { isAuth, currentRegion, currentDomain, currentLanguage } from 'server-data/footer-locale-selector';
import 'css/flux/layout/LocaleSelector.less';

/**
 * Performs a
 * [`SameValueZero`](http://ecma-international.org/ecma-262/6.0/#sec-samevaluezero)
 * comparison between two values to determine if they are equivalent.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param {*} value The value to compare.
 * @param {*} other The other value to compare.
 * @returns {boolean} Returns `true` if the values are equivalent, else `false`.
 * @example
 *
 * var object = { 'a': 1 };
 * var other = { 'a': 1 };
 *
 * _.eq(object, object);
 * // => true
 *
 * _.eq(object, other);
 * // => false
 *
 * _.eq('a', 'a');
 * // => true
 *
 * _.eq('a', Object('a'));
 * // => false
 *
 * _.eq(NaN, NaN);
 * // => true
 */
function eq(value, other) {
  return value === other || value !== value && other !== other;
}

/** Used for built-in method references. */
var objectProto = Object.prototype;

/** Used to check objects for own properties. */
var hasOwnProperty = objectProto.hasOwnProperty;

/**
 * Used by `_.defaults` to customize its `_.assignIn` use.
 *
 * @private
 * @param {*} objValue The destination value.
 * @param {*} srcValue The source value.
 * @param {string} key The key of the property to assign.
 * @param {Object} object The parent object of `objValue`.
 * @returns {*} Returns the value to assign.
 */
function assignInDefaults(objValue, srcValue, key, object) {
  if (objValue === undefined || eq(objValue, objectProto[key]) && !hasOwnProperty.call(object, key)) {
    return srcValue;
  }
  return objValue;
}

/** Used for built-in method references. */
var objectProto$1 = Object.prototype;

/** Used to check objects for own properties. */
var hasOwnProperty$1 = objectProto$1.hasOwnProperty;

/**
 * Assigns `value` to `key` of `object` if the existing value is not equivalent
 * using [`SameValueZero`](http://ecma-international.org/ecma-262/6.0/#sec-samevaluezero)
 * for equality comparisons.
 *
 * @private
 * @param {Object} object The object to modify.
 * @param {string} key The key of the property to assign.
 * @param {*} value The value to assign.
 */
function assignValue(object, key, value) {
  var objValue = object[key];
  if (!(hasOwnProperty$1.call(object, key) && eq(objValue, value)) || value === undefined && !(key in object)) {
    object[key] = value;
  }
}

/**
 * Copies properties of `source` to `object`.
 *
 * @private
 * @param {Object} source The object to copy properties from.
 * @param {Array} props The property identifiers to copy.
 * @param {Object} [object={}] The object to copy properties to.
 * @param {Function} [customizer] The function to customize copied values.
 * @returns {Object} Returns `object`.
 */
function copyObject(source, props, object, customizer) {
  object || (object = {});

  var index = -1,
      length = props.length;

  while (++index < length) {
    var key = props[index];

    var newValue = customizer ? customizer(object[key], source[key], key, object, source) : undefined;

    assignValue(object, key, newValue === undefined ? source[key] : newValue);
  }
  return object;
}

/**
 * A faster alternative to `Function#apply`, this function invokes `func`
 * with the `this` binding of `thisArg` and the arguments of `args`.
 *
 * @private
 * @param {Function} func The function to invoke.
 * @param {*} thisArg The `this` binding of `func`.
 * @param {Array} args The arguments to invoke `func` with.
 * @returns {*} Returns the result of `func`.
 */
function apply(func, thisArg, args) {
  switch (args.length) {
    case 0:
      return func.call(thisArg);
    case 1:
      return func.call(thisArg, args[0]);
    case 2:
      return func.call(thisArg, args[0], args[1]);
    case 3:
      return func.call(thisArg, args[0], args[1], args[2]);
  }
  return func.apply(thisArg, args);
}

/* Built-in method references for those with the same name as other `lodash` methods. */
var nativeMax = Math.max;

/**
 * The base implementation of `_.rest` which doesn't validate or coerce arguments.
 *
 * @private
 * @param {Function} func The function to apply a rest parameter to.
 * @param {number} [start=func.length-1] The start position of the rest parameter.
 * @returns {Function} Returns the new function.
 */
function baseRest(func, start) {
  start = nativeMax(start === undefined ? func.length - 1 : start, 0);
  return function () {
    var args = arguments,
        index = -1,
        length = nativeMax(args.length - start, 0),
        array = Array(length);

    while (++index < length) {
      array[index] = args[start + index];
    }
    index = -1;
    var otherArgs = Array(start + 1);
    while (++index < start) {
      otherArgs[index] = args[index];
    }
    otherArgs[start] = array;
    return apply(func, this, otherArgs);
  };
}

/**
 * The base implementation of `_.property` without support for deep paths.
 *
 * @private
 * @param {string} key The key of the property to get.
 * @returns {Function} Returns the new accessor function.
 */
function baseProperty(key) {
  return function (object) {
    return object == null ? undefined : object[key];
  };
}

/**
 * Gets the "length" property value of `object`.
 *
 * **Note:** This function is used to avoid a
 * [JIT bug](https://bugs.webkit.org/show_bug.cgi?id=142792) that affects
 * Safari on at least iOS 8.1-8.3 ARM64.
 *
 * @private
 * @param {Object} object The object to query.
 * @returns {*} Returns the "length" value.
 */
var getLength = baseProperty('length');

/**
 * Checks if `value` is the
 * [language type](http://www.ecma-international.org/ecma-262/6.0/#sec-ecmascript-language-types)
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
 * @example
 *
 * _.isObject({});
 * // => true
 *
 * _.isObject([1, 2, 3]);
 * // => true
 *
 * _.isObject(_.noop);
 * // => true
 *
 * _.isObject(null);
 * // => false
 */
function isObject(value) {
  var type = typeof value;
  return !!value && (type == 'object' || type == 'function');
}

/** `Object#toString` result references. */
var funcTag = '[object Function]',
    genTag = '[object GeneratorFunction]';

/** Used for built-in method references. */
var objectProto$2 = Object.prototype;

/**
 * Used to resolve the
 * [`toStringTag`](http://ecma-international.org/ecma-262/6.0/#sec-object.prototype.tostring)
 * of values.
 */
var objectToString = objectProto$2.toString;

/**
 * Checks if `value` is classified as a `Function` object.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a function, else `false`.
 * @example
 *
 * _.isFunction(_);
 * // => true
 *
 * _.isFunction(/abc/);
 * // => false
 */
function isFunction(value) {
  // The use of `Object#toString` avoids issues with the `typeof` operator
  // in Safari 8 which returns 'object' for typed array and weak map constructors,
  // and PhantomJS 1.9 which returns 'function' for `NodeList` instances.
  var tag = isObject(value) ? objectToString.call(value) : '';
  return tag == funcTag || tag == genTag;
}

/** Used as references for various `Number` constants. */
var MAX_SAFE_INTEGER = 9007199254740991;

/**
 * Checks if `value` is a valid array-like length.
 *
 * **Note:** This function is loosely based on
 * [`ToLength`](http://ecma-international.org/ecma-262/6.0/#sec-tolength).
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a valid length,
 *  else `false`.
 * @example
 *
 * _.isLength(3);
 * // => true
 *
 * _.isLength(Number.MIN_VALUE);
 * // => false
 *
 * _.isLength(Infinity);
 * // => false
 *
 * _.isLength('3');
 * // => false
 */
function isLength(value) {
  return typeof value == 'number' && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
}

/**
 * Checks if `value` is array-like. A value is considered array-like if it's
 * not a function and has a `value.length` that's an integer greater than or
 * equal to `0` and less than or equal to `Number.MAX_SAFE_INTEGER`.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is array-like, else `false`.
 * @example
 *
 * _.isArrayLike([1, 2, 3]);
 * // => true
 *
 * _.isArrayLike(document.body.children);
 * // => true
 *
 * _.isArrayLike('abc');
 * // => true
 *
 * _.isArrayLike(_.noop);
 * // => false
 */
function isArrayLike(value) {
  return value != null && isLength(getLength(value)) && !isFunction(value);
}

/** Used as references for various `Number` constants. */
var MAX_SAFE_INTEGER$1 = 9007199254740991;

/** Used to detect unsigned integer values. */
var reIsUint = /^(?:0|[1-9]\d*)$/;

/**
 * Checks if `value` is a valid array-like index.
 *
 * @private
 * @param {*} value The value to check.
 * @param {number} [length=MAX_SAFE_INTEGER] The upper bounds of a valid index.
 * @returns {boolean} Returns `true` if `value` is a valid index, else `false`.
 */
function isIndex(value, length) {
  length = length == null ? MAX_SAFE_INTEGER$1 : length;
  return !!length && (typeof value == 'number' || reIsUint.test(value)) && value > -1 && value % 1 == 0 && value < length;
}

/**
 * Checks if the given arguments are from an iteratee call.
 *
 * @private
 * @param {*} value The potential iteratee value argument.
 * @param {*} index The potential iteratee index or key argument.
 * @param {*} object The potential iteratee object argument.
 * @returns {boolean} Returns `true` if the arguments are from an iteratee call,
 *  else `false`.
 */
function isIterateeCall(value, index, object) {
  if (!isObject(object)) {
    return false;
  }
  var type = typeof index;
  if (type == 'number' ? isArrayLike(object) && isIndex(index, object.length) : type == 'string' && index in object) {
    return eq(object[index], value);
  }
  return false;
}

/**
 * Creates a function like `_.assign`.
 *
 * @private
 * @param {Function} assigner The function to assign values.
 * @returns {Function} Returns the new assigner function.
 */
function createAssigner(assigner) {
  return baseRest(function (object, sources) {
    var index = -1,
        length = sources.length,
        customizer = length > 1 ? sources[length - 1] : undefined,
        guard = length > 2 ? sources[2] : undefined;

    customizer = assigner.length > 3 && typeof customizer == 'function' ? (length--, customizer) : undefined;

    if (guard && isIterateeCall(sources[0], sources[1], guard)) {
      customizer = length < 3 ? undefined : customizer;
      length = 1;
    }
    object = Object(object);
    while (++index < length) {
      var source = sources[index];
      if (source) {
        assigner(object, source, index, customizer);
      }
    }
    return object;
  });
}

/** Detect free variable `global` from Node.js. */
var freeGlobal = typeof global == 'object' && global && global.Object === Object && global;

/** Detect free variable `self`. */
var freeSelf = typeof self == 'object' && self && self.Object === Object && self;

/** Used as a reference to the global object. */
var root = freeGlobal || freeSelf || Function('return this')();

/** Built-in value references. */
var Reflect = root.Reflect;

/**
 * Converts `iterator` to an array.
 *
 * @private
 * @param {Object} iterator The iterator to convert.
 * @returns {Array} Returns the converted array.
 */
function iteratorToArray(iterator) {
  var data,
      result = [];

  while (!(data = iterator.next()).done) {
    result.push(data.value);
  }
  return result;
}

/** Used for built-in method references. */
var objectProto$3 = Object.prototype;

/** Built-in value references. */
var enumerate = Reflect ? Reflect.enumerate : undefined,
    propertyIsEnumerable = objectProto$3.propertyIsEnumerable;

/**
 * The base implementation of `_.keysIn` which doesn't skip the constructor
 * property of prototypes or treat sparse arrays as dense.
 *
 * @private
 * @param {Object} object The object to query.
 * @returns {Array} Returns the array of property names.
 */
function baseKeysIn(object) {
  object = object == null ? object : Object(object);

  var result = [];
  for (var key in object) {
    result.push(key);
  }
  return result;
}

// Fallback for IE < 9 with es6-shim.
if (enumerate && !propertyIsEnumerable.call({ 'valueOf': 1 }, 'valueOf')) {
  baseKeysIn = function baseKeysIn(object) {
    return iteratorToArray(enumerate(object));
  };
}

var baseKeysIn$1 = baseKeysIn;

/**
 * The base implementation of `_.times` without support for iteratee shorthands
 * or max array length checks.
 *
 * @private
 * @param {number} n The number of times to invoke `iteratee`.
 * @param {Function} iteratee The function invoked per iteration.
 * @returns {Array} Returns the array of results.
 */
function baseTimes(n, iteratee) {
  var index = -1,
      result = Array(n);

  while (++index < n) {
    result[index] = iteratee(index);
  }
  return result;
}

/**
 * Checks if `value` is object-like. A value is object-like if it's not `null`
 * and has a `typeof` result of "object".
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is object-like, else `false`.
 * @example
 *
 * _.isObjectLike({});
 * // => true
 *
 * _.isObjectLike([1, 2, 3]);
 * // => true
 *
 * _.isObjectLike(_.noop);
 * // => false
 *
 * _.isObjectLike(null);
 * // => false
 */
function isObjectLike(value) {
  return !!value && typeof value == 'object';
}

/**
 * This method is like `_.isArrayLike` except that it also checks if `value`
 * is an object.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an array-like object,
 *  else `false`.
 * @example
 *
 * _.isArrayLikeObject([1, 2, 3]);
 * // => true
 *
 * _.isArrayLikeObject(document.body.children);
 * // => true
 *
 * _.isArrayLikeObject('abc');
 * // => false
 *
 * _.isArrayLikeObject(_.noop);
 * // => false
 */
function isArrayLikeObject(value) {
  return isObjectLike(value) && isArrayLike(value);
}

/** `Object#toString` result references. */
var argsTag = '[object Arguments]';

/** Used for built-in method references. */
var objectProto$4 = Object.prototype;

/** Used to check objects for own properties. */
var hasOwnProperty$2 = objectProto$4.hasOwnProperty;

/**
 * Used to resolve the
 * [`toStringTag`](http://ecma-international.org/ecma-262/6.0/#sec-object.prototype.tostring)
 * of values.
 */
var objectToString$1 = objectProto$4.toString;

/** Built-in value references. */
var propertyIsEnumerable$1 = objectProto$4.propertyIsEnumerable;

/**
 * Checks if `value` is likely an `arguments` object.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an `arguments` object,
 *  else `false`.
 * @example
 *
 * _.isArguments(function() { return arguments; }());
 * // => true
 *
 * _.isArguments([1, 2, 3]);
 * // => false
 */
function isArguments(value) {
  // Safari 8.1 incorrectly makes `arguments.callee` enumerable in strict mode.
  return isArrayLikeObject(value) && hasOwnProperty$2.call(value, 'callee') && (!propertyIsEnumerable$1.call(value, 'callee') || objectToString$1.call(value) == argsTag);
}

/**
 * Checks if `value` is classified as an `Array` object.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an array, else `false`.
 * @example
 *
 * _.isArray([1, 2, 3]);
 * // => true
 *
 * _.isArray(document.body.children);
 * // => false
 *
 * _.isArray('abc');
 * // => false
 *
 * _.isArray(_.noop);
 * // => false
 */
var isArray = Array.isArray;

/** `Object#toString` result references. */
var stringTag = '[object String]';

/** Used for built-in method references. */
var objectProto$5 = Object.prototype;

/**
 * Used to resolve the
 * [`toStringTag`](http://ecma-international.org/ecma-262/6.0/#sec-object.prototype.tostring)
 * of values.
 */
var objectToString$2 = objectProto$5.toString;

/**
 * Checks if `value` is classified as a `String` primitive or object.
 *
 * @static
 * @since 0.1.0
 * @memberOf _
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a string, else `false`.
 * @example
 *
 * _.isString('abc');
 * // => true
 *
 * _.isString(1);
 * // => false
 */
function isString(value) {
  return typeof value == 'string' || !isArray(value) && isObjectLike(value) && objectToString$2.call(value) == stringTag;
}

/**
 * Creates an array of index keys for `object` values of arrays,
 * `arguments` objects, and strings, otherwise `null` is returned.
 *
 * @private
 * @param {Object} object The object to query.
 * @returns {Array|null} Returns index keys, else `null`.
 */
function indexKeys(object) {
  var length = object ? object.length : undefined;
  if (isLength(length) && (isArray(object) || isString(object) || isArguments(object))) {
    return baseTimes(length, String);
  }
  return null;
}

/** Used for built-in method references. */
var objectProto$6 = Object.prototype;

/**
 * Checks if `value` is likely a prototype object.
 *
 * @private
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a prototype, else `false`.
 */
function isPrototype(value) {
  var Ctor = value && value.constructor,
      proto = typeof Ctor == 'function' && Ctor.prototype || objectProto$6;

  return value === proto;
}

/** Used for built-in method references. */
var objectProto$7 = Object.prototype;

/** Used to check objects for own properties. */
var hasOwnProperty$3 = objectProto$7.hasOwnProperty;

/**
 * Creates an array of the own and inherited enumerable property names of `object`.
 *
 * **Note:** Non-object values are coerced to objects.
 *
 * @static
 * @memberOf _
 * @since 3.0.0
 * @category Object
 * @param {Object} object The object to query.
 * @returns {Array} Returns the array of property names.
 * @example
 *
 * function Foo() {
 *   this.a = 1;
 *   this.b = 2;
 * }
 *
 * Foo.prototype.c = 3;
 *
 * _.keysIn(new Foo);
 * // => ['a', 'b', 'c'] (iteration order is not guaranteed)
 */
function keysIn(object) {
  var index = -1,
      isProto = isPrototype(object),
      props = baseKeysIn$1(object),
      propsLength = props.length,
      indexes = indexKeys(object),
      skipIndexes = !!indexes,
      result = indexes || [],
      length = result.length;

  while (++index < propsLength) {
    var key = props[index];
    if (!(skipIndexes && (key == 'length' || isIndex(key, length))) && !(key == 'constructor' && (isProto || !hasOwnProperty$3.call(object, key)))) {
      result.push(key);
    }
  }
  return result;
}

/**
 * This method is like `_.assignIn` except that it accepts `customizer`
 * which is invoked to produce the assigned values. If `customizer` returns
 * `undefined`, assignment is handled by the method instead. The `customizer`
 * is invoked with five arguments: (objValue, srcValue, key, object, source).
 *
 * **Note:** This method mutates `object`.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @alias extendWith
 * @category Object
 * @param {Object} object The destination object.
 * @param {...Object} sources The source objects.
 * @param {Function} [customizer] The function to customize assigned values.
 * @returns {Object} Returns `object`.
 * @see _.assignWith
 * @example
 *
 * function customizer(objValue, srcValue) {
 *   return _.isUndefined(objValue) ? srcValue : objValue;
 * }
 *
 * var defaults = _.partialRight(_.assignInWith, customizer);
 *
 * defaults({ 'a': 1 }, { 'b': 2 }, { 'a': 3 });
 * // => { 'a': 1, 'b': 2 }
 */
var assignInWith = createAssigner(function (object, source, srcIndex, customizer) {
  copyObject(source, keysIn(source), object, customizer);
});

/** `Object#toString` result references. */
var errorTag = '[object Error]';

/** Used for built-in method references. */
var objectProto$8 = Object.prototype;

/**
 * Used to resolve the
 * [`toStringTag`](http://ecma-international.org/ecma-262/6.0/#sec-object.prototype.tostring)
 * of values.
 */
var objectToString$3 = objectProto$8.toString;

/**
 * Checks if `value` is an `Error`, `EvalError`, `RangeError`, `ReferenceError`,
 * `SyntaxError`, `TypeError`, or `URIError` object.
 *
 * @static
 * @memberOf _
 * @since 3.0.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an error object,
 *  else `false`.
 * @example
 *
 * _.isError(new Error);
 * // => true
 *
 * _.isError(Error);
 * // => false
 */
function isError(value) {
  if (!isObjectLike(value)) {
    return false;
  }
  return objectToString$3.call(value) == errorTag || typeof value.message == 'string' && typeof value.name == 'string';
}

/**
 * Attempts to invoke `func`, returning either the result or the caught error
 * object. Any additional arguments are provided to `func` when it's invoked.
 *
 * @static
 * @memberOf _
 * @since 3.0.0
 * @category Util
 * @param {Function} func The function to attempt.
 * @param {...*} [args] The arguments to invoke `func` with.
 * @returns {*} Returns the `func` result or error object.
 * @example
 *
 * // Avoid throwing errors for invalid selectors.
 * var elements = _.attempt(function(selector) {
 *   return document.querySelectorAll(selector);
 * }, '>_>');
 *
 * if (_.isError(elements)) {
 *   elements = [];
 * }
 */
var attempt = baseRest(function (func, args) {
  try {
    return apply(func, undefined, args);
  } catch (e) {
    return isError(e) ? e : new Error(e);
  }
});

/**
 * A specialized version of `_.map` for arrays without support for iteratee
 * shorthands.
 *
 * @private
 * @param {Array} [array] The array to iterate over.
 * @param {Function} iteratee The function invoked per iteration.
 * @returns {Array} Returns the new mapped array.
 */
function arrayMap(array, iteratee) {
  var index = -1,
      length = array ? array.length : 0,
      result = Array(length);

  while (++index < length) {
    result[index] = iteratee(array[index], index, array);
  }
  return result;
}

/**
 * The base implementation of `_.values` and `_.valuesIn` which creates an
 * array of `object` property values corresponding to the property names
 * of `props`.
 *
 * @private
 * @param {Object} object The object to query.
 * @param {Array} props The property names to get values for.
 * @returns {Object} Returns the array of property values.
 */
function baseValues(object, props) {
  return arrayMap(props, function (key) {
    return object[key];
  });
}

/** Used to escape characters for inclusion in compiled string literals. */
var stringEscapes = {
  '\\': '\\',
  "'": "'",
  '\n': 'n',
  '\r': 'r',
  '\u2028': 'u2028',
  '\u2029': 'u2029'
};

/**
 * Used by `_.template` to escape characters for inclusion in compiled string literals.
 *
 * @private
 * @param {string} chr The matched character to escape.
 * @returns {string} Returns the escaped character.
 */
function escapeStringChar(chr) {
  return '\\' + stringEscapes[chr];
}

/**
 * Creates a function that invokes `func` with its first argument transformed.
 *
 * @private
 * @param {Function} func The function to wrap.
 * @param {Function} transform The argument transform.
 * @returns {Function} Returns the new function.
 */
function overArg(func, transform) {
  return function (arg) {
    return func(transform(arg));
  };
}

/* Built-in method references for those with the same name as other `lodash` methods. */
var nativeGetPrototype = Object.getPrototypeOf;

/**
 * Gets the `[[Prototype]]` of `value`.
 *
 * @private
 * @param {*} value The value to query.
 * @returns {null|Object} Returns the `[[Prototype]]`.
 */
var getPrototype = overArg(nativeGetPrototype, Object);

/** Used for built-in method references. */
var objectProto$9 = Object.prototype;

/** Used to check objects for own properties. */
var hasOwnProperty$4 = objectProto$9.hasOwnProperty;

/**
 * The base implementation of `_.has` without support for deep paths.
 *
 * @private
 * @param {Object} [object] The object to query.
 * @param {Array|string} key The key to check.
 * @returns {boolean} Returns `true` if `key` exists, else `false`.
 */
function baseHas(object, key) {
  // Avoid a bug in IE 10-11 where objects with a [[Prototype]] of `null`,
  // that are composed entirely of index properties, return `false` for
  // `hasOwnProperty` checks of them.
  return object != null && (hasOwnProperty$4.call(object, key) || typeof object == 'object' && key in object && getPrototype(object) === null);
}

/* Built-in method references for those with the same name as other `lodash` methods. */
var nativeKeys = Object.keys;

/**
 * The base implementation of `_.keys` which doesn't skip the constructor
 * property of prototypes or treat sparse arrays as dense.
 *
 * @private
 * @param {Object} object The object to query.
 * @returns {Array} Returns the array of property names.
 */
var baseKeys = overArg(nativeKeys, Object);

/**
 * Creates an array of the own enumerable property names of `object`.
 *
 * **Note:** Non-object values are coerced to objects. See the
 * [ES spec](http://ecma-international.org/ecma-262/6.0/#sec-object.keys)
 * for more details.
 *
 * @static
 * @since 0.1.0
 * @memberOf _
 * @category Object
 * @param {Object} object The object to query.
 * @returns {Array} Returns the array of property names.
 * @example
 *
 * function Foo() {
 *   this.a = 1;
 *   this.b = 2;
 * }
 *
 * Foo.prototype.c = 3;
 *
 * _.keys(new Foo);
 * // => ['a', 'b'] (iteration order is not guaranteed)
 *
 * _.keys('hi');
 * // => ['0', '1']
 */
function keys(object) {
  var isProto = isPrototype(object);
  if (!(isProto || isArrayLike(object))) {
    return baseKeys(object);
  }
  var indexes = indexKeys(object),
      skipIndexes = !!indexes,
      result = indexes || [],
      length = result.length;

  for (var key in object) {
    if (baseHas(object, key) && !(skipIndexes && (key == 'length' || isIndex(key, length))) && !(isProto && key == 'constructor')) {
      result.push(key);
    }
  }
  return result;
}

/** Used to match template delimiters. */
var reInterpolate = /<%=([\s\S]+?)%>/g;

/**
 * The base implementation of `_.propertyOf` without support for deep paths.
 *
 * @private
 * @param {Object} object The object to query.
 * @returns {Function} Returns the new accessor function.
 */
function basePropertyOf(object) {
  return function (key) {
    return object == null ? undefined : object[key];
  };
}

/** Used to map characters to HTML entities. */
var htmlEscapes = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '`': '&#96;'
};

/**
 * Used by `_.escape` to convert characters to HTML entities.
 *
 * @private
 * @param {string} chr The matched character to escape.
 * @returns {string} Returns the escaped character.
 */
var escapeHtmlChar = basePropertyOf(htmlEscapes);

/** Built-in value references. */
var Symbol = root.Symbol;

/** `Object#toString` result references. */
var symbolTag = '[object Symbol]';

/** Used for built-in method references. */
var objectProto$10 = Object.prototype;

/**
 * Used to resolve the
 * [`toStringTag`](http://ecma-international.org/ecma-262/6.0/#sec-object.prototype.tostring)
 * of values.
 */
var objectToString$4 = objectProto$10.toString;

/**
 * Checks if `value` is classified as a `Symbol` primitive or object.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a symbol, else `false`.
 * @example
 *
 * _.isSymbol(Symbol.iterator);
 * // => true
 *
 * _.isSymbol('abc');
 * // => false
 */
function isSymbol(value) {
  return typeof value == 'symbol' || isObjectLike(value) && objectToString$4.call(value) == symbolTag;
}

/** Used as references for various `Number` constants. */
var INFINITY = 1 / 0;

/** Used to convert symbols to primitives and strings. */
var symbolProto = Symbol ? Symbol.prototype : undefined,
    symbolToString = symbolProto ? symbolProto.toString : undefined;

/**
 * The base implementation of `_.toString` which doesn't convert nullish
 * values to empty strings.
 *
 * @private
 * @param {*} value The value to process.
 * @returns {string} Returns the string.
 */
function baseToString(value) {
  // Exit early for strings to avoid a performance hit in some environments.
  if (typeof value == 'string') {
    return value;
  }
  if (isSymbol(value)) {
    return symbolToString ? symbolToString.call(value) : '';
  }
  var result = value + '';
  return result == '0' && 1 / value == -INFINITY ? '-0' : result;
}

/**
 * Converts `value` to a string. An empty string is returned for `null`
 * and `undefined` values. The sign of `-0` is preserved.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param {*} value The value to process.
 * @returns {string} Returns the string.
 * @example
 *
 * _.toString(null);
 * // => ''
 *
 * _.toString(-0);
 * // => '-0'
 *
 * _.toString([1, 2, 3]);
 * // => '1,2,3'
 */
function toString(value) {
  return value == null ? '' : baseToString(value);
}

/** Used to match HTML entities and HTML characters. */
var reUnescapedHtml = /[&<>"'`]/g,
    reHasUnescapedHtml = RegExp(reUnescapedHtml.source);

/**
 * Converts the characters "&", "<", ">", '"', "'", and "\`" in `string` to
 * their corresponding HTML entities.
 *
 * **Note:** No other characters are escaped. To escape additional
 * characters use a third-party library like [_he_](https://mths.be/he).
 *
 * Though the ">" character is escaped for symmetry, characters like
 * ">" and "/" don't need escaping in HTML and have no special meaning
 * unless they're part of a tag or unquoted attribute value. See
 * [Mathias Bynens's article](https://mathiasbynens.be/notes/ambiguous-ampersands)
 * (under "semi-related fun fact") for more details.
 *
 * Backticks are escaped because in IE < 9, they can break out of
 * attribute values or HTML comments. See [#59](https://html5sec.org/#59),
 * [#102](https://html5sec.org/#102), [#108](https://html5sec.org/#108), and
 * [#133](https://html5sec.org/#133) of the
 * [HTML5 Security Cheatsheet](https://html5sec.org/) for more details.
 *
 * When working with HTML you should always
 * [quote attribute values](http://wonko.com/post/html-escaping) to reduce
 * XSS vectors.
 *
 * @static
 * @since 0.1.0
 * @memberOf _
 * @category String
 * @param {string} [string=''] The string to escape.
 * @returns {string} Returns the escaped string.
 * @example
 *
 * _.escape('fred, barney, & pebbles');
 * // => 'fred, barney, &amp; pebbles'
 */
function escape$1(string) {
    string = toString(string);
    return string && reHasUnescapedHtml.test(string) ? string.replace(reUnescapedHtml, escapeHtmlChar) : string;
}

/** Used to match template delimiters. */
var reEscape = /<%-([\s\S]+?)%>/g;

/** Used to match template delimiters. */
var reEvaluate = /<%([\s\S]+?)%>/g;

/**
 * By default, the template delimiters used by lodash are like those in
 * embedded Ruby (ERB). Change the following template settings to use
 * alternative delimiters.
 *
 * @static
 * @memberOf _
 * @type {Object}
 */
var templateSettings = {

  /**
   * Used to detect `data` property values to be HTML-escaped.
   *
   * @memberOf _.templateSettings
   * @type {RegExp}
   */
  'escape': reEscape,

  /**
   * Used to detect code to be evaluated.
   *
   * @memberOf _.templateSettings
   * @type {RegExp}
   */
  'evaluate': reEvaluate,

  /**
   * Used to detect `data` property values to inject.
   *
   * @memberOf _.templateSettings
   * @type {RegExp}
   */
  'interpolate': reInterpolate,

  /**
   * Used to reference the data object in the template text.
   *
   * @memberOf _.templateSettings
   * @type {string}
   */
  'variable': '',

  /**
   * Used to import variables into the compiled template.
   *
   * @memberOf _.templateSettings
   * @type {Object}
   */
  'imports': {

    /**
     * A reference to the `lodash` function.
     *
     * @memberOf _.templateSettings.imports
     * @type {Function}
     */
    '_': { 'escape': escape$1 }
  }
};

/** Used to match empty string literals in compiled template source. */
var reEmptyStringLeading = /\b__p \+= '';/g,
    reEmptyStringMiddle = /\b(__p \+=) '' \+/g,
    reEmptyStringTrailing = /(__e\(.*?\)|\b__t\)) \+\n'';/g;

/**
 * Used to match
 * [ES template delimiters](http://ecma-international.org/ecma-262/6.0/#sec-template-literal-lexical-components).
 */
var reEsTemplate = /\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g;

/** Used to ensure capturing order of template delimiters. */
var reNoMatch = /($^)/;

/** Used to match unescaped characters in compiled string literals. */
var reUnescapedString = /['\n\r\u2028\u2029\\]/g;

/**
 * Creates a compiled template function that can interpolate data properties
 * in "interpolate" delimiters, HTML-escape interpolated data properties in
 * "escape" delimiters, and execute JavaScript in "evaluate" delimiters. Data
 * properties may be accessed as free variables in the template. If a setting
 * object is given, it takes precedence over `_.templateSettings` values.
 *
 * **Note:** In the development build `_.template` utilizes
 * [sourceURLs](http://www.html5rocks.com/en/tutorials/developertools/sourcemaps/#toc-sourceurl)
 * for easier debugging.
 *
 * For more information on precompiling templates see
 * [lodash's custom builds documentation](https://lodash.com/custom-builds).
 *
 * For more information on Chrome extension sandboxes see
 * [Chrome's extensions documentation](https://developer.chrome.com/extensions/sandboxingEval).
 *
 * @static
 * @since 0.1.0
 * @memberOf _
 * @category String
 * @param {string} [string=''] The template string.
 * @param {Object} [options={}] The options object.
 * @param {RegExp} [options.escape=_.templateSettings.escape]
 *  The HTML "escape" delimiter.
 * @param {RegExp} [options.evaluate=_.templateSettings.evaluate]
 *  The "evaluate" delimiter.
 * @param {Object} [options.imports=_.templateSettings.imports]
 *  An object to import into the template as free variables.
 * @param {RegExp} [options.interpolate=_.templateSettings.interpolate]
 *  The "interpolate" delimiter.
 * @param {string} [options.sourceURL='templateSources[n]']
 *  The sourceURL of the compiled template.
 * @param {string} [options.variable='obj']
 *  The data object variable name.
 * @param- {Object} [guard] Enables use as an iteratee for methods like `_.map`.
 * @returns {Function} Returns the compiled template function.
 * @example
 *
 * // Use the "interpolate" delimiter to create a compiled template.
 * var compiled = _.template('hello <%= user %>!');
 * compiled({ 'user': 'fred' });
 * // => 'hello fred!'
 *
 * // Use the HTML "escape" delimiter to escape data property values.
 * var compiled = _.template('<b><%- value %></b>');
 * compiled({ 'value': '<script>' });
 * // => '<b>&lt;script&gt;</b>'
 *
 * // Use the "evaluate" delimiter to execute JavaScript and generate HTML.
 * var compiled = _.template('<% _.forEach(users, function(user) { %><li><%- user %></li><% }); %>');
 * compiled({ 'users': ['fred', 'barney'] });
 * // => '<li>fred</li><li>barney</li>'
 *
 * // Use the internal `print` function in "evaluate" delimiters.
 * var compiled = _.template('<% print("hello " + user); %>!');
 * compiled({ 'user': 'barney' });
 * // => 'hello barney!'
 *
 * // Use the ES delimiter as an alternative to the default "interpolate" delimiter.
 * var compiled = _.template('hello ${ user }!');
 * compiled({ 'user': 'pebbles' });
 * // => 'hello pebbles!'
 *
 * // Use backslashes to treat delimiters as plain text.
 * var compiled = _.template('<%= "\\<%- value %\\>" %>');
 * compiled({ 'value': 'ignored' });
 * // => '<%- value %>'
 *
 * // Use the `imports` option to import `jQuery` as `jq`.
 * var text = '<% jq.each(users, function(user) { %><li><%- user %></li><% }); %>';
 * var compiled = _.template(text, { 'imports': { 'jq': jQuery } });
 * compiled({ 'users': ['fred', 'barney'] });
 * // => '<li>fred</li><li>barney</li>'
 *
 * // Use the `sourceURL` option to specify a custom sourceURL for the template.
 * var compiled = _.template('hello <%= user %>!', { 'sourceURL': '/basic/greeting.jst' });
 * compiled(data);
 * // => Find the source of "greeting.jst" under the Sources tab or Resources panel of the web inspector.
 *
 * // Use the `variable` option to ensure a with-statement isn't used in the compiled template.
 * var compiled = _.template('hi <%= data.user %>!', { 'variable': 'data' });
 * compiled.source;
 * // => function(data) {
 * //   var __t, __p = '';
 * //   __p += 'hi ' + ((__t = ( data.user )) == null ? '' : __t) + '!';
 * //   return __p;
 * // }
 *
 * // Use custom template delimiters.
 * _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
 * var compiled = _.template('hello {{ user }}!');
 * compiled({ 'user': 'mustache' });
 * // => 'hello mustache!'
 *
 * // Use the `source` property to inline compiled templates for meaningful
 * // line numbers in error messages and stack traces.
 * fs.writeFileSync(path.join(process.cwd(), 'jst.js'), '\
 *   var JST = {\
 *     "main": ' + _.template(mainText).source + '\
 *   };\
 * ');
 */
function template(string, options, guard) {
  // Based on John Resig's `tmpl` implementation
  // (http://ejohn.org/blog/javascript-micro-templating/)
  // and Laura Doktorova's doT.js (https://github.com/olado/doT).
  var settings = templateSettings.imports._.templateSettings || templateSettings;

  if (guard && isIterateeCall(string, options, guard)) {
    options = undefined;
  }
  string = toString(string);
  options = assignInWith({}, options, settings, assignInDefaults);

  var imports = assignInWith({}, options.imports, settings.imports, assignInDefaults),
      importsKeys = keys(imports),
      importsValues = baseValues(imports, importsKeys);

  var isEscaping,
      isEvaluating,
      index = 0,
      interpolate = options.interpolate || reNoMatch,
      source = "__p += '";

  // Compile the regexp to match each delimiter.
  var reDelimiters = RegExp((options.escape || reNoMatch).source + '|' + interpolate.source + '|' + (interpolate === reInterpolate ? reEsTemplate : reNoMatch).source + '|' + (options.evaluate || reNoMatch).source + '|$', 'g');

  // Use a sourceURL for easier debugging.
  var sourceURL = 'sourceURL' in options ? '//# sourceURL=' + options.sourceURL + '\n' : '';

  string.replace(reDelimiters, function (match, escapeValue, interpolateValue, esTemplateValue, evaluateValue, offset) {
    interpolateValue || (interpolateValue = esTemplateValue);

    // Escape characters that can't be included in string literals.
    source += string.slice(index, offset).replace(reUnescapedString, escapeStringChar);

    // Replace delimiters with snippets.
    if (escapeValue) {
      isEscaping = true;
      source += "' +\n__e(" + escapeValue + ") +\n'";
    }
    if (evaluateValue) {
      isEvaluating = true;
      source += "';\n" + evaluateValue + ";\n__p += '";
    }
    if (interpolateValue) {
      source += "' +\n((__t = (" + interpolateValue + ")) == null ? '' : __t) +\n'";
    }
    index = offset + match.length;

    // The JS engine embedded in Adobe products needs `match` returned in
    // order to produce the correct `offset` value.
    return match;
  });

  source += "';\n";

  // If `variable` is not specified wrap a with-statement around the generated
  // code to add the data object to the top of the scope chain.
  var variable = options.variable;
  if (!variable) {
    source = 'with (obj) {\n' + source + '\n}\n';
  }
  // Cleanup code by stripping empty strings.
  source = (isEvaluating ? source.replace(reEmptyStringLeading, '') : source).replace(reEmptyStringMiddle, '$1').replace(reEmptyStringTrailing, '$1;');

  // Frame code as the function body.
  source = 'function(' + (variable || 'obj') + ') {\n' + (variable ? '' : 'obj || (obj = {});\n') + "var __t, __p = ''" + (isEscaping ? ', __e = _.escape' : '') + (isEvaluating ? ', __j = Array.prototype.join;\n' + "function print() { __p += __j.call(arguments, '') }\n" : ';\n') + source + 'return __p\n}';

  var result = attempt(function () {
    return Function(importsKeys, sourceURL + 'return ' + source).apply(undefined, importsValues);
  });

  // Provide the compiled function's source by its `toString` method or
  // the `source` property as a convenience for inlining compiled templates.
  result.source = source;
  if (isError(result)) {
    throw result;
  }
  return result;
}

var _classCallCheck = (function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
});

var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

/**
 * Loads the contents of an SVG file and injects it to where it's being called.
 * In its originality this function is only used with the Underscore templating
 * engine, so it only works if the template is being rendered right after
 * compilation.
 * @param  {String} icon The icon name. Consult with FEDs or the
 * [Pattern Library](https://patternlibrary.freelancer.com)
 *                       for the naming convention of the icon you're using.
 * @return {String}      A temporary placeholder element to be replaced later
 * after the XHR fetches the SVG.
 */

function generateUniqueIdentifier() {
  var identifier = 'uiIcon-' + Math.floor(Math.random() * 1024);
  // Does the passwordless form with that identifier exist? Reroll the
  // dice again to get a new unique one.
  if (document.getElementById(identifier)) {
    return generateUniqueIdentifier();
  }

  return identifier;
}

function uiIcon(icon) {
  // Make a placeholder element to be replaced with the SVG later.
  var identifier = generateUniqueIdentifier();
  var placeholderHTML = '<span id="' + identifier + '"></span>';

  // Fetch the contents of the SVG file we're going to replace it with.
  var placeholderNode = void 0;
  // DO NOT use the SystemJS API without talking to @laurent first! Things
  // might go really bad if you do.
  fetch(SystemJS.normalizeSync('icons/' + icon + '.svg')).then(function (response) {
    if (response.ok) {
      return response.text();
    }

    // Remove the placeholder element if the icon is not found or some server
    // problems happened.
    placeholderNode = document.getElementById('' + identifier);
    if (placeholderNode.parentNode) {
      placeholderNode.parentNode.removeChild(placeholderNode);
    }
    throw new Error();
  }).then(function (html) {
    var replace = function replace() {
      placeholderNode = document.getElementById('' + identifier);
      // Sometimes the templates are rendered but never inserted in the DOM
      // ¯\_(ツ)_/¯
      if (placeholderNode) {
        placeholderNode.outerHTML = html;
      }
    };

    // Replace the placeholder element at the next animation frame if possible
    if ('requestAnimationFrame' in window) {
      requestAnimationFrame(replace);
    } else {
      replace();
    }
    // 404 or something else happened. Don't worry about it.
  }).catch(function () {
    return undefined;
  });

  // While that call's happening return the placeholder element to be injected
  // to the template first.
  return placeholderHTML;
}

var template$1 = "<div id=\"<%=  id  %>\" class=\"Modal <%=  extraClass  %>\" data-modal aria-hidden=\"<%=  hideModal  %>\">\n  <div class=\"Modal-inner\" <%  if (backdropClose) {  %> data-modal-outbound <%  }  %>>\n    <div class=\"Modal-dialog\" role=\"dialog\">\n      <div class=\"Modal-content Card\" role=\"document\">\n        <%  if (closeButton) {  %>\n        <button class=\"Modal-close\" type=\"button\" data-modal-hide aria-label=\"Close this modal window\">\n          <span class=\"Icon Icon--small\"><%=  uiIcon('ui-close')  %></span>\n        </button>\n        <%  }  %>\n        <%  if (header) {  %>\n        <header class=\"Modal-header\">\n          <%=  header  %>\n        </header>\n        <%  }  %>\n        <%  if (body) {  %>\n        <div class=\"Modal-body\">\n          <%=  body  %>\n        </div>\n        <%  }  %>\n        <%  if (footer) {  %>\n        <footer class=\"Modal-footer\">\n          <%=  footer  %>\n        </footer>\n        <%  }  %>\n      </div>\n    </div>\n  </div>\n  <div class=\"Modal-overlay\" tabindex=\"-1\"></div>\n</div>\n";

var OPEN_EVENT = {
  METHOD: 'method'
};

var CLOSE_EVENT = {
  ESC: 'esc',
  OUTOFBOUNDS: 'outofbounds',
  ELEMENT: 'element'
};

var SIZE = {
  SMALL: 'Modal--small',
  LARGE: 'Modal--large',
  FULLSCREEN: 'Modal--fullscreen'
};

var defaultOptions = {
  main: document.querySelector('#main'),
  closeButton: true,
  backdropClose: true,
  extraClass: '',
  size: '',
  type: ''
};

// Template the modal's scaffolding by injecting header, body and footer
var scaffold = template(template$1);

/**
 * Modal constructor
 * @param {Node} node Dialog element
 * @param {Node} main Main element of the page
 */

var Modal = function () {
  /**
   * Modal constructor
   * @param  {String}   id                       An ID to apply to the modal.
   * @param  {Object}   content                  Content object to inject in the
   *                                             modal.
   * @param  {String}   content.header           Content for the header.
   * @param  {String}   content.body             Content for the body.
   * @param  {String}   content.footer           Content for the footer.
   * @param  {Object}   [options={}]             Options object for customise
   *                                             behaviour of modal.
   * @param  {Node}     [options.main]           An element to treat as the main
   *                                             content so that
   *                                             aria-hidden="true" can be
   *                                             toggled. Defaults to #main.
   * @param  {Boolean}  [options.closeButton]    Shows the x close button.
   *                                             Defaults to true.
   * @param  {Boolean}  [options.backdropClose]  Enables closing of modal via
   *                                             clicking on the backdrop.
   *                                             Defaults to true.
   * @param  {String}   [options.extraClass]     Adds extra classes to the
   *                                             modal.
   * @param  {String}   [options.size]           Sets the size of the modal.
   * @param  {String}   [options.type]           Sets the type of the modal.
   * @param  {Function} [options.onShow]         Callback function after showing
   *                                             the modal.
   * @param  {Function} [options.onHide]        Callback function after closing
   *                                             the modal.
   * @return {Modal}                             Modal instance.
   */
  function Modal(id, content, opts) {
    _classCallCheck(this, Modal);

    // Merge user options with defaultOptions
    var options = Object.assign({}, defaultOptions, opts);

    // Build configuration
    this.config = {
      id: id,
      content: {
        header: content.header,
        body: content.body,
        footer: content.footer
      },
      options: options
    };

    // Errors
    if (typeof this.config.id === 'undefined') {
      throw new TypeError('Modal id cannot be undefined.');
    }

    if (typeof this.config.content === 'undefined') {
      throw new TypeError('Modal content cannot be undefined.');
    }

    if (typeof this.config.options.main === 'undefined') {
      throw new TypeError('#main element does not exist.');
    }

    this.namespace = 'data-modal';
    this.body = document.body;
    this.main = this.config.options.main;

    // Appends markup to the body just before the </body>
    this.body.insertAdjacentHTML('beforeend', scaffold({
      uiIcon: uiIcon,
      id: this.config.id,
      hideModal: true,
      header: this.config.content.header,
      body: this.config.content.body,
      footer: this.config.content.footer,
      closeButton: this.config.options.closeButton,
      backdropClose: this.config.options.backdropClose,
      extraClass: this.config.options.extraClass + ' ' + (this.config.options.size + ' ') + ('' + this.config.options.type)
    }));

    this.node = document.getElementById(this.config.id);
    this.shown = false;

    this._bindEventsToModal();
    return this;
  }

  /**
   * Helper function that binds modal events to the modal element
   */

  _createClass(Modal, [{
    key: '_bindEventsToModal',
    value: function _bindEventsToModal() {
      var _this = this;

      // Bind show modal event to elements with <this.namespace>-show="<modal-id>"
      // data attributes
      Array.from(
      // Get all the elements with <this.namespace>-show="<modal-id>"
      document.querySelectorAll('[' + this.namespace + '-show="' + this.node.id + '"]')).forEach(function (opener) {
        opener.addEventListener('click', function () {
          return _this.show();
        });
      });

      // Bind hide modal event to elements with <this.namespace>-hide data
      // attributesmodules/membership/index.js
      Array.from(
      // Get all elements with <this.namespace>-hide inside the modal
      this.node.querySelectorAll('[' + this.namespace + '-hide]'))
      // Then combine it with all elements on the page that targets the modal
      // with <this.namespace>-hide="<modal-id>" for hiding
      .concat(Array.from(document.querySelectorAll('[' + this.namespace + '-hide="' + this.node.id + '"]'))).forEach(function (closer) {
        closer.addEventListener('click', function () {
          return _this.hide(CLOSE_EVENT.ELEMENT);
        });
      });

      // Hides modal when the user clicks out of bounds
      this.node.addEventListener('click', function (event) {
        if (event.target.hasAttribute(_this.namespace + '-outbound')) {
          _this.hide(CLOSE_EVENT.OUTOFBOUNDS);
        }
      });

      document.addEventListener('keydown', function (event) {
        // keydown on Esc key
        if (_this.shown && event.which === 27) {
          event.preventDefault();
          _this.hide(CLOSE_EVENT.ESC);
        }

        // keydown on Tab key
        if (_this.shown && event.which === 9) {
          _this._trapTabKey(_this.node, event);
        }
      });

      this.body.addEventListener('focus', function (event) {
        if (_this.shown && !_this.node.contains(event.target)) {
          _this._setFocusToFirstItem(_this.node);
        }
      }, true);
    }

    /**
     * Helper function to get all focusable children from a node
     * @param  {Node} node Node to look in
     * @return {Array}     Array of focusable nodes
     */

  }, {
    key: '_getFocusableChildren',
    value: function _getFocusableChildren(node) {
      var focusableElements = ['a[href]', 'area[href]', 'input:not([disabled])', 'select:not([disabled])', 'textarea:not([disabled])', 'button:not([disabled])', 'iframe', 'object', 'embed', '[contenteditable]', '[tabindex]:not([tabindex^="-"])'];

      return Array.from(node.querySelectorAll(focusableElements.join(','))).filter(function (child) {
        return !!(child.offsetWidth || child.offsetHeight || child.getClientRects().length);
      });
    }

    /**
     * Helper function trapping the tab key inside a node
     * @param  {Node} node    Node to trap tab key in
     * @param  {Object} event Event to intercept
     */

  }, {
    key: '_trapTabKey',
    value: function _trapTabKey(node, event) {
      var focusableChildren = this._getFocusableChildren(node);
      var focusedItemIndex = focusableChildren.indexOf(document.activeElement);

      if (event.shiftKey && focusedItemIndex === 0) {
        focusableChildren[focusableChildren.length - 1].focus();
        event.preventDefault();
      } else if (!event.shiftKey && focusedItemIndex === focusableChildren.length - 1) {
        focusableChildren[0].focus();
        event.preventDefault();
      }
    }

    /**
     * Helper function to focus first focusable item in node
     * @param {Node} node Node to search in for first focusable
     */

  }, {
    key: '_setFocusToFirstItem',
    value: function _setFocusToFirstItem(node) {
      var focusableChildren = this._getFocusableChildren(node);
      if (focusableChildren.length) focusableChildren[0].focus();
    }

    /**
     * Checks if scrollbar is visible.
     * Ported from the bootstrap v3.3.6.
     */

  }, {
    key: '_checkScrollbar',
    value: function _checkScrollbar() {
      var fullWindowWidth = window.innerWidth;

      this.bodyIsOverflowing = this.body.clientWidth < fullWindowWidth;
      this.scrollbarWidth = this._measureScrollbar();
    }

    /**
     * Sets the scrollbar padding offset on the body element.
     * Ported from the bootstrap v3.3.6.
     */

  }, {
    key: '_setScrollbar',
    value: function _setScrollbar() {
      var bodyPadding = parseInt(this.body.style.paddingRight || 0, 10);

      this.originalBodyPadding = this.body.style.paddingRight || '';

      if (this.bodyIsOverflowing) {
        this.body.style.paddingRight = '' + bodyPadding + this.scrollbarWidth + 'px';
      }
    }

    /**
     * Resets the padding on the body to the way it was with the scrollbar.
     * Ported from the bootstrap v3.3.6.
     */

  }, {
    key: '_resetScrollbar',
    value: function _resetScrollbar() {
      this.body.style.paddingRight = this.originalBodyPadding;
    }

    /**
     * Measures the width of the scrollbar.
     * Ported from the bootstrap v3.3.6.
     *
     * @return {Number} Width of the scrollbar.
     */

  }, {
    key: '_measureScrollbar',
    value: function _measureScrollbar() {
      var scrollDiv = document.createElement('div');
      // Injects some styling to the element which forces the display of a scroll
      // bar and places it off the page to ensure it is never visible.
      scrollDiv.style.position = 'absolute';
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.top = '-9999px';
      scrollDiv.style.width = '100px';
      scrollDiv.style.height = '100px';

      this.body.appendChild(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      this.body.removeChild(scrollDiv);
      return scrollbarWidth;
    }

    /**
     * Show the modal with optional eventType identifier
     * @param  {String} eventType An identifier string to pass into the onShow
     *                            callback method.
     */

  }, {
    key: 'show',
    value: function show(eventType) {
      this.shown = true;
      this._checkScrollbar();
      this._setScrollbar();
      this.body.setAttribute(this.namespace + '-open', '');
      this.node.removeAttribute('aria-hidden');
      this.main.setAttribute('aria-hidden', 'true');
      this.focusedBeforeDialog = document.activeElement;
      this._setFocusToFirstItem(this.node);
      eventType = eventType || OPEN_EVENT.METHOD;

      if (this.config.options.onShow) {
        this.config.options.onShow(eventType);
      }
    }

    /**
     * Hide the modal
     * @param  {String} eventType An identifier string to pass into the onHide
     *                            callback method.
     */

  }, {
    key: 'hide',
    value: function hide(eventType) {
      this.shown = false;
      this._resetScrollbar();
      this.body.removeAttribute(this.namespace + '-open', '');
      this.node.setAttribute('aria-hidden', 'true');
      this.main.removeAttribute('aria-hidden');
      this.focusedBeforeDialog.focus();

      if (this.config.options.onHide) {
        this.config.options.onHide(eventType);
      }
    }

    /**
     * Dynamically change the template of the modal given the new template.
     * Use this if you want to change the content without creating a new modal
     * as this does not automatically hide the new content upon replacement
     *
     * @param  {Object} content Content object to inject in the modal.
     */

  }, {
    key: 'replaceTemplate',
    value: function replaceTemplate(content) {
      var newModalContent = scaffold({
        uiIcon: uiIcon,
        id: this.config.id,
        hideModal: false,
        header: content.header,
        body: content.body,
        footer: content.footer,
        closeButton: this.config.options.closeButton,
        backdropClose: this.config.options.backdropClose,
        extraClass: this.config.options.extraClass + ' ' + (this.config.options.size + ' ') + ('' + this.config.options.type)
      });

      // convert content to nodes
      var newModal = document.createElement('div');
      newModal.innerHTML = newModalContent;
      var newTemplate = newModal.childNodes[0];

      // replace with new template
      var fragment = document.createDocumentFragment();
      Array.prototype.forEach.call(newTemplate.children, function (child) {
        var childNode = child instanceof Node ? child : document.createTextNode(String(child));
        fragment.appendChild(childNode);
      });

      this.node.replaceChild(fragment, this.node.children[0]);

      // bind modal events
      this.node = document.getElementById(this.config.id);
      this._bindEventsToModal();
    }
  }]);

  return Modal;
}();

/** `Object#toString` result references. */
var numberTag = '[object Number]';

/** Used for built-in method references. */
var objectProto$11 = Object.prototype;

/**
 * Used to resolve the
 * [`toStringTag`](http://ecma-international.org/ecma-262/6.0/#sec-object.prototype.tostring)
 * of values.
 */
var objectToString$5 = objectProto$11.toString;

/**
 * Checks if `value` is classified as a `Number` primitive or object.
 *
 * **Note:** To exclude `Infinity`, `-Infinity`, and `NaN`, which are
 * classified as numbers, use the `_.isFinite` method.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a number, else `false`.
 * @example
 *
 * _.isNumber(3);
 * // => true
 *
 * _.isNumber(Number.MIN_VALUE);
 * // => true
 *
 * _.isNumber(Infinity);
 * // => true
 *
 * _.isNumber('3');
 * // => false
 */
function isNumber(value) {
  return typeof value == 'number' || isObjectLike(value) && objectToString$5.call(value) == numberTag;
}

function setCookie(cName, value, expiredays) {
  var expiremins = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  exdate.setMinutes(exdate.getMinutes() + expiremins);
  document.cookie = cName + '=' + value + ('' + (expiredays == null ? '' : ';expires=' + exdate.toUTCString())) + '; path=/; secure';
}

function getCookie(cName) {
  var cookies = document.cookie;
  if (cookies.length > 0) {
    var cStart = cookies.indexOf(cName + '=');
    if (cStart != -1) {
      cStart = cStart + cName.length + 1;
      var cEnd = cookies.indexOf(';', cStart);
      if (cEnd == -1) cEnd = cookies.length;
      return cookies.substring(cStart, cEnd);
    }
  }
  return '';
}

function randomString(length, chars) {
  var result = '';
  for (var i = length; i > 0; --i) {
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  }
  return result;
}

function getCSRFToken() {
  var token = getCookie('XSRF-TOKEN');
  if (!token) {
    token = randomString(64, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    setCookie('XSRF-TOKEN', token, 7);
  }
  return token;
}

var TRANSLATE_MODE_COOKIE = cookieBase + '_TRANSLATE_MODE';

var isTranslateModeEnabled = function isTranslateModeEnabled() {
  return getCookie(TRANSLATE_MODE_COOKIE) === '1';
};

var getTokenizedMessage = function getTokenizedMessage(id, message) {
  return isTranslateModeEnabled() ? '~=[' + id + '|^|' + message + ']=~' : message;
};

var translate = function translate(first) {
  for (var _len = arguments.length, rest = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    rest[_key - 1] = arguments[_key];
  }

  switch (true) {
    case isString(first):
      {
        var text = first;
        var vars = rest[0];
        if (typeof vars === 'undefined' || !vars) {
          return text;
        }
        var result = template(text, {
          evaluate: /<\[([\s\S]+?)\]>/g,
          interpolate: /<\[=([\s\S]+?)\]>/g,
          escape: /<\[-([\s\S]+?)\]>/g
        })(vars);
        result = template(result)(vars);
        return result;
      }
    case isArray(first):
      {
        return oneLine([translate.raw.apply(translate, [first].concat(rest))]);
      }
    case isNumber(first):
      {
        var _ret = function () {
          var sourceTextId = first;
          return {
            v: function v() {
              return getTokenizedMessage(sourceTextId, translate.apply(undefined, arguments));
            }
          };
        }();

        if (typeof _ret === "object") return _ret.v;
      }
    default:
      {
        throw new TypeError('Invalid argument types');
      }
  }
};

translate.raw = function rawT(literals) {
  for (var _len2 = arguments.length, placeholders = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    placeholders[_key2 - 1] = arguments[_key2];
  }

  return literals.raw.reduce(function (str, current, index) {
    var temp = str + current;
    if (index < placeholders.length) {
      temp += placeholders[index].toString();
    }
    return temp;
  }, '');
};

var bodyTemplate = "<%  domains.forEach(function (region) {  %>\n    <div class=\"LocaleSelector-region\">\n        <h3 class=\"LocaleSelector-region-title\"><%-  region.name  %></h3>\n        <ul class=\"LocaleSelector-countries\">\n            <%  region.domains.forEach(function (country) {  %>\n                <li class=\"LocaleSelector-countries-item\">\n                        <a href=\"<%=  country.url  %>\" class=\"LocaleSelector-country <%  if (country.name === currentDomain) {  %> LocaleSelector-country--default <%  }  %>\" data-country=\"<%  country.name  %>\">\n                            <span class=\"small-flag <%-  country.short_name  %>\"></span> <%-  country.name  %>\n                        </a>\n                    </li>\n            <%  });  %>\n        </ul>\n    </div>\n<%  });  %>\n";

var headerTemplate = "<div class=\"Card-heading\">\n    <%- T_('Zgjidhni Sajtin e Vendit') %>\n</div>\n";

var footerTemplate = "<form id=\"language-selector-form\" action=\"/changeLanguage.php\" method=\"POST\" class=\"LocaleSelector-languages\">\n    <label for=\"language-selector\" class=\"LocaleSelector-languages-label\"><%- T_('Gjuha') %></label>\n    <select id=\"language-selector\" name=\"lang\" class=\"default-select\">\n        <%  languages.forEach(function(language) {  %>\n            <option value=\"<%-  language.code  %>\" <%  if (language.name === currentLanguage) {  %> selected <%  }  %>>\n                <%-  language.name  %>\n            </option>\n        <%  });  %>\n    </select>\n    <input type=\"hidden\" name=\"csrf_token\">\n    <input type=\"hidden\" name=\"last_page\">\n</form>\n";

/**
 * Change the language of the site by submitting the form to the PHP endpoint
 * that changes the language, then brings the user back to the previous page
 * they are in.
 * @param  {Node} form The form element.
 */
function changeLanguage(formEl) {
  var searchParam = window.location.search.toString().substr(1).split('&').filter(/./.test.bind(/^((?!t=\w).)*$/)).join('&');
  var filteredSearchString = searchParam ? '?' + searchParam : '';

  // Populate form data with CSRF Token & stripped redirect URL then submit it.
  formEl.csrf_token.value = getCSRFToken();
  formEl.last_page.value = window.location.pathname + filteredSearchString + window.location.hash;
  formEl.submit();
}

/**
 * Creates the popover and initializes the initial value on the inputs and
 * links.
 * @param  {Array} domains   The domains of Freelancer sent by the AJAX
 *                           endpoint called from loadLanguageSelectorData.
 * @param  {Array} languages The languages of Freelancer sent by the AJAX
 *                           endpoint called from loadLanguageSelectorData.
 */
function render(domains, languages) {
  // Initialize the popover
  var modalTemplates = {
    header: template(headerTemplate)({
      T_: translate
    }),
    body: template(bodyTemplate)({
      domains: domains,
      T_: translate,
      currentRegion: currentRegion,
      currentDomain: currentDomain
    }),
    // Only show the language selector if user is authenticated
    footer: !isAuth ? '' : template(footerTemplate)({
      T_: translate,
      languages: languages,
      currentLanguage: currentLanguage
    })
  };

  var modal = new Modal('locale-selector-modal', modalTemplates, {
    size: SIZE.LARGE,
    extraClass: 'LocaleSelector'
  });

  var languageSelectorEl = document.getElementById('language-selector');
  var languageSelectorFormEl = document.getElementById('language-selector-form');
  var buttonEl = document.getElementById('locale-selector');

  // Set the events necessary
  // Event to trigger language switch based on the dropdown
  if (languageSelectorEl) {
    languageSelectorEl.addEventListener('change', function () {
      return changeLanguage(languageSelectorFormEl);
    });
  }

  // For a11y, set the button to `role="button"` when the JS has kicked in.
  buttonEl.setAttribute('role', 'button');

  // Event to trigger the popover
  buttonEl.addEventListener('click', function (event) {
    // Cancel the default <a> tag behaviour.
    event.preventDefault();
    modal.show();
  });
}

// Fetch the information needed to set up the popover.
fetch('/ajax/pinky/footer.php').then(function (response) {
  if (response.ok) {
    return response.json();
  }

  var error = new Error(response.statusText);
  error.response = response;
  throw error;
}).then(function (response) {
  function onReadyHandler() {
    render(response.data.domains, response.data.languages);
  }

  if (document.readyState !== 'loading') {
    onReadyHandler();
  } else {
    document.addEventListener('DOMContentLoaded', onReadyHandler);
  }
});
